.error-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 5rem;
        color: red;
    }

    p {
        margin-top: space(2);
        font-weight: 600;
        font-size: 1rem;
    }
}
