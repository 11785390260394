.price-details {
    padding: space(5);
    display: flex;
    justify-content: space-between;
    min-width: 400px;
    line-height: 2;
}

.quantity-details {
    padding: space(5);
    display: grid;
    gap: space(5);
}
