.nav {
    background-color: var(--color-primary);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    transition: max-width 0.3s ease, min-width 0.3s ease;
    min-width: var(--nav-width);
    max-width: var(--nav-width);
    height: var(--vh100);
    position: sticky;
    top: 0;

    .nav__button {
        padding: space(3);
        height: 90px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--color-white);
        white-space: nowrap;

        .nav__toggle {
            margin-right: space(3);
        }
    }

    .nav__bar {
        &--1,
        &--2,
        &--3 {
            width: 24px;
            height: 2px;
            display: block;
            background-color: var(--color-white);
            position: relative;
            transition: transform 0.3s ease;
        }

        &--1 {
            transform: translateY(-6px);
        }
        &--2 {
            width: 18px;
        }
        &--3 {
            transform: translateY(6px);
        }
    }

    .nav__inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .nav__link {
        margin-left: space(1);

        a {
            color: var(--color-white);
            padding: space(2) 0;
            padding-left: space(2);
            display: flex;
            white-space: nowrap;
            padding-right: space(3);

            &.active {
                background-color: var(--color-light);
                color: var(--color-primary);
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    height: 48px;
                    width: 24px;
                    position: absolute;
                    right: 0;
                    pointer-events: none;
                    background-color: transparent;
                }

                &::before {
                    top: -48px;
                    border-bottom-right-radius: 16px;
                    box-shadow: 0 16px 0 0 var(--color-light);
                }
                &::after {
                    bottom: -48px;
                    border-top-right-radius: 16px;
                    box-shadow: 0 -16px 0 0 var(--color-light);
                }
            }
        }
    }

    svg {
        margin-right: space(3);
        min-width: 24px;
    }

    &.is-open {
        min-width: 200px;
        max-width: 200px;

        .nav__bar {
            &--1 {
                transform: translateY(2px) rotate(45deg);
            }
            &--2 {
                transform: scale(0);
            }
            &--3 {
                transform: translateY(-2px) rotate(-45deg);
            }
        }
    }
}
