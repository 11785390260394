.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    padding: space(5);

    .modal {
        z-index: 100;
        background: var(--color-light);
        position: relative;
        margin: auto;
        border-radius: var(--border-radius);
        overflow: hidden;
        animation: 0.5s ease slideInTop;
        display: flex;
        flex-direction: column;

        @include up(768) {
            max-width: fit-content;
        }
    }

    .modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: var(--vh100);
        background-color: rgba(var(--rgb-primary), 0.6);
        animation: 0.5s ease fadeIn;
    }

    &.right {
        .modal {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0;
            border-top-left-radius: 24px;
            overflow-y: auto;
            animation: 0.5s ease slideInRight;
        }

        &.hide {
            .modal {
                animation: 0.5s ease forwards slideOutRight;
            }
        }
    }

    &.hide {
        .modal__overlay {
            pointer-events: none;
            animation: 0.5s ease forwards fadeOut;
        }

        .modal {
            animation: 0.5s ease forwards slideOutBottom;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes slideInTop {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideOutBottom {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(20px);
        opacity: 0;
    }
}

.modal__header {
    padding: space(4);
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: 1;
}

.modal__footer {
    background-color: var(--color-white);
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
