.text-lg {
    font-size: 1.5rem;
    font-weight: 600;
}
.text-sm {
    font-size: 0.75rem;
    font-weight: bold;
}
.text-link {
    color: var(--color-primary);
    font-weight: 500;
}

.text-error {
    color: var(--color-error);
}
.text-warning {
    color: var(--color-warning);
}
.text-success {
    color: var(--color-success);
}

.text-bold {
    font-weight: bold;
}
.text-nowrap {
    white-space: nowrap;
}
.text-right {
    text-align: right;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-center {
    text-align: center;
}
