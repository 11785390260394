.button {
    background-color: var(--color-primary);
    color: var(--color-white);
    text-align: center;
    padding: space(1) space(2);
    position: relative;
    border-radius: var(--border-radius);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &.button-outline {
        background-color: var(--color-white);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
    }

    .spinner-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .three-quarter-spinner {
        width: 1rem;
        height: 1rem;
    }

    svg {
        margin-right: space(1);
        height: 0.75rem;
        width: 0.75rem;
    }
}

.button:disabled {
    color: var(--color-primary);
}

.button-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: var(--color-primary);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    margin-right: space(1);

    &.error {
        background-color: var(--color-error);
    }

    &.success {
        background-color: var(--color-success);
    }

    &:last-child {
        margin-right: 0;
    }

    & svg {
        height: 20px;
        width: 20px;
    }

    &:disabled {
        opacity: 0.35;
    }
}

.button-secondary {
    color: var(--color-primary);
}
