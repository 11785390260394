// Flex
// ==================
.flex {
    display: flex;

    &.center {
        align-items: center;
        justify-content: center;
    }
    &.space {
        flex: 1;
        align-items: center;
        justify-content: space-between;
    }
    &.right {
        justify-content: flex-end;
    }
    &.left {
        justify-content: flex-start;
    }
    &.column {
        flex-direction: column;
    }

    // Spacing
    &.sl-1 {
        > * {
            margin-left: space(1);
        }
    }
    &.sl-2 {
        > * {
            margin-left: space(2);
        }
    }

    &.sl-1,
    &.sl-2 {
        > *:first-child {
            margin-left: 0;
        }
    }
}
.flex-1 {
    flex: 1;
}

// Grid
// ==================
