.form {
    display: grid;
    gap: space(4);
    padding: space(4);
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    min-width: 435px;

    label,
    .label {
        font-size: 0.75rem;
        font-weight: bold;
        display: inline-block;
        margin-bottom: space(1);
    }
}

input,
textarea {
    width: 100%;
    border: 1px solid var(--color-primary);
    padding: space(1) space(2);
    border-radius: var(--border-radius);
    outline: none;
    transition: border 0.3s ease;

    &:placeholder-shown {
        border-color: rgba(var(--rgb-primary), 0.3);
    }

    &::placeholder {
        color: rgba(var(--rgb-primary), 0.3);
    }

    &:focus {
        border-color: var(--color-primary);
    }

    &.error {
        opacity: 1;
        border-color: var(--color-error);
    }
}

textarea {
    resize: none;
}

.input-select {
    width: 100%;
    border: 1px solid var(--color-primary);
    padding: space(1) space(2);
    border-radius: var(--border-radius);
    outline: none;
    transition: border 0.3s ease;

    &.error {
        border-color: var(--color-error);
    }
}

.input-icon {
    position: relative;
    overflow: hidden;
    transition: opacity 0.3s ease;

    &:focus-within {
        opacity: 1;
    }

    .icon {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        top: 2px;
        bottom: 2px;
        padding: 0 space(2);
        cursor: pointer;
    }

    svg {
        color: var(--color-primary);
    }

    &.error {
        svg {
            color: var(--color-error);
        }
    }
}

.input-search {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--color-light);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: 0 space(2);
    border: 1px solid var(--color-light);
    transition: border 0.3s ease;
    width: 100%;
    max-width: 300px;
    font-size: 0.875rem;

    input {
        border: none;
        background-color: var(--color-light);
        padding-right: 0;
        padding-left: space(2);
    }

    button {
        position: absolute;
        right: 0;
        height: 100%;
        padding: 0 space(2);
        background-color: var(--color-light);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:focus-within {
        border: 1px solid var(--color-primary);
    }
}

.input-dropdown {
    position: relative;

    .input-dropdown__button {
        border-radius: var(--border-radius);
        border: 1px solid var(--color-secondary);
        padding: space(1) space(2);
        display: flex;
        align-items: center;

        .counter {
            font-weight: 500;
            color: var(--color-white);
            background-color: var(--color-primary);
            border-radius: 4px;
            padding: 0 4px;
            margin-right: space(1);
        }
    }

    button svg {
        margin-left: space(1);
        transition: transform 0.2s ease;
    }

    .dropdown-inner {
        position: absolute;
        right: 0;
        top: calc(100% + 8px);
        background-color: var(--color-white);
        border-radius: var(--border-radius);
        border: 1px solid var(--color-primary);
        padding-top: space(1);
        display: none;
        height: auto;
        max-height: 50vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        min-width: 250px;

        &::-webkit-scrollbar {
            display: none;
        }

        input[type='checkbox'] {
            position: absolute;
            left: 0;
            opacity: 0.01;
        }

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            white-space: nowrap;
            cursor: pointer;
            border-bottom: 2px solid var(--color-light);
            padding: space(1) space(3);
            transition: background-color 0.3s ease;

            svg {
                margin-left: space(4);
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        input[type='checkbox']:checked+label {
            background-color: var(--color-light);

            svg {
                opacity: 1;
            }
        }

        li:last-child label {
            border-bottom: none;
        }
    }

    .dropdown-inner__footer {
        position: sticky;
        bottom: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        button {
            border: 1px solid var(--color-primary);

            &:first-child {
                border-left: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &.is-open {
        .input-dropdown__button {
            border: 1px solid var(--color-primary);
        }

        button svg {
            transform: rotate(180deg);
        }

        .dropdown-inner {
            display: block;
        }
    }
}

.input-range {
    padding: space(4);
    min-width: 300px;

    .input-range__values {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .input-range__slider {
        margin: 0 12px;
        margin-top: space(4);
        margin-bottom: space(2);
    }

    .rc-slider-rail {
        background-color: var(--color-secondary);
    }

    .rc-slider-track {
        background-color: var(--color-primary);
    }

    .rc-slider-handle {
        border: 2px solid var(--color-primary);
        width: 24px;
        height: 24px;
        margin-top: 0;
        top: 50%;
        transform: translate(-50%, -50%) !important;

        &:hover {
            border-color: var(--color-primary);
        }
    }

    .rc-slider-handle-click-focused:focus {
        border-color: var(--color-primary);
        box-shadow: none;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: var(--color-primary);
        box-shadow: none;
    }
}

.input-quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        font-size: 2rem;
        display: flex;
        align-items: center;

        &:disabled {
            opacity: 0.25;
            cursor: not-allowed;
        }
    }

    input {
        width: 46px;
        margin: 0 4px;
        padding: space(1) 0;
        text-align: center;
        border: none;

        &::placeholder {
            color: inherit;
        }

        &.disabled {
            opacity: 0.25;
        }
    }
}

.input-reduction {
    color: var(--color-success);

    input {
        width: 30px;
        border: none;
        padding: space(1) 0;
        padding-right: 4px;
        text-align: right;
        color: inherit;

        &::placeholder {
            color: var(--color-primary);
        }
    }

    &.disabled {
        color: var(--color-primary);
        opacity: 0.4;
    }
}

.input-reduction,
.input-price,
.input-quantity {

    // hide controls
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.input-date {
    .input-date-label {
        cursor: pointer;
    }

    .input-date-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: space(1) space(2);
        border: 1px solid var(--color-primary);
        border-radius: var(--border-radius);
    }
}

.input-price {
    color: var(--color-primary);

    input {
        width: 45px;
        border: none;
        padding: space(1) 0;
        padding-right: 4px;
        text-align: right;
        color: inherit;

        &::placeholder {
            color: var(--color-primary);
        }
    }
}

.input-checkbox {
    display: flex;

    .input-checkbox__label {
        padding: space(1) space(2);
        display: block;
        border-radius: var(--border-radius);
        border: 1px solid var(--color-secondary);
        cursor: pointer;
        white-space: nowrap;
        font-size: 1em;
        font-weight: 400;
        text-transform: none;
        color: var(--color-dark);
        line-height: 1.6;
        margin: 0;

        &:global(.error) {
            color: var(--color-error);
        }
    }

    .input-checkbox__input {
        width: 0;
        height: 0;
    }

    .input-checkbox__input:checked {
        background-color: var(--color-primary);
        color: var(--color-light);
        border: 1px solid var(--color-primary);
        position: relative;
    }

    .input-checkbox__input:checked+.input-checkbox__label {
        color: var(--color-light);
        background-color: var(--color-primary);
        padding-right: 40px;
        position: relative;
    }

    .input-checkbox__input:checked+.input-checkbox__label::after {
        content: '✓';
        font-weight: bold;
        z-index: 1;
        color: var(--color-complementary);
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}