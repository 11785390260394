// Responsive
// ==================
@mixin up($size) {
    @media (min-width: $size * 1px) {
        @content;
    }
}
@mixin down($size) {
    @media (max-width: $size * 1px) {
        @content;
    }
}

@mixin orientation($orientation) {
    @media (orientation: $orientation) {
        @content;
    }
}

// Espacements
// ==================
@function space($i) {
    @return calc(#{$i} * var(--space));
}
