.badge {
    display: inline-block;
    background-color: var(--color-light);
    border-radius: 20px;
    padding: 0 space(2);
    margin-right: space(1);
    font-size: 0.75rem;
    height: 22px;
    line-height: 22px;

    &:last-child {
        margin-right: 0;
    }

    &.error,
    &.warning,
    &.success {
        height: auto;
        font-size: 1em;
        padding: 4px space(2);
    }

    &.error {
        color: var(--color-error);
        background-color: rgba(var(--rgb-error), 0.1);
    }
    &.warning {
        color: var(--color-warning);
        background-color: rgba(var(--rgb-warning), 0.1);
    }
    &.success {
        color: var(--color-success);
        background-color: rgba(var(--rgb-success), 0.2);
    }
}
