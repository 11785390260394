.table-wrapper {
    overflow-x: auto;
}

.table {
    width: 100%;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;

    th,
    td {
        padding: space(2) space(3);
        white-space: nowrap;
        vertical-align: middle;

        &.px-0 {
            padding-left: 0;
            padding-right: 0;
        }

        &.w-100 {
            width: 100%;
        }
        &.right {
            text-align: right;
        }
    }

    thead {
        text-align: left;
        font-size: 0.75rem;
        font-weight: bold;
        // position: sticky;
        // top: 113px;

        &::before,
        &::after {
            content: '';
            height: 16px;
            width: 8px;
            top: 0;
            position: absolute;
            pointer-events: none;
            background-color: var(--color-white);
            box-shadow: 0 -8px 0 0 var(--color-light);
        }

        &::before {
            left: 0;
            border-top-left-radius: 8px;
        }
        &::after {
            right: 0;
            border-top-right-radius: 8px;
        }

        th {
            background-color: var(--color-white);
            padding-top: space(1);
            padding-bottom: space(1);
            border-bottom: 1px solid var(--color-primary);

            &:last-child {
                position: sticky;
                right: 0;
                background-color: var(--color-white);
            }
        }
    }

    th.is-clickable {
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
            margin-left: space(1);
        }
    }

    tbody {
        td {
            border-bottom: 1px solid var(--color-secondary);

            &:last-child {
                position: sticky;
                right: 0;
                background-color: var(--color-white);
            }
        }
    }

    img {
        width: 100%;
        height: 66px;
        object-fit: cover;
    }
}

//reset
.rs-table-hover .rs-table-row:hover .rs-table-cell {
    background: var(--color-white) !important;
}

// NEW
.table {
    border: none !important;

    //borders
    .rs-table-row {
        border-bottom: 1px solid var(--color-secondary);

        &:last-child {
            border-bottom: none;
        }
    }
    .rs-table-row-header {
        border-bottom: 1px solid var(--color-primary) !important;
    }

    .rs-table-row.row-error .rs-table-cell,
    .rs-table-hover .rs-table-row:hover,
    .rs-table-hover .rs-table-row.row-error:hover .rs-table-cell {
        background-color: rgba(var(--rgb-error), 0.2) !important;
    }

    //Table head
    .rs-table-row-header .rs-table-cell {
        background-color: var(--color-white);

        .rs-table-cell-content {
            font-weight: bold;
            font-size: 0.75rem;

            padding-top: space(1);
            padding-bottom: space(1);
        }
    }

    .rs-table-cell-content {
        white-space: normal;
        display: flex;
        align-items: center;
        padding: space(2) space(3);

        p {
            width: 100%;
        }
    }

    //Image cell
    .image-cell {
        width: 100px;

        .rs-table-cell-content {
            padding: 0;
        }
    }

    .name-cell .name-cell__ref {
        font-size: 0.875rem;
        line-height: 1.4;
    }

    .name-cell .rs-table-cell-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    //filters
    .rs-table-cell-header-sort-wrapper {
        font-size: 0rem;
        margin-left: 5px;
    }
    .rs-table-cell-header-icon-sort-desc::after,
    .rs-table-cell-header-icon-sort-asc::after {
        color: var(--color-primary);
    }
    .rs-table-cell-header-icon-sort::after {
        color: red;
        content: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.95641 8.93816C8.01602 8.88261 8.06384 8.81562 8.09701 8.74118C8.13017 8.66675 8.148 8.5864 8.14944 8.50493C8.15088 8.42345 8.13589 8.34252 8.10537 8.26697C8.07485 8.19141 8.02943 8.12277 7.97181 8.06515C7.91419 8.00753 7.84555 7.96211 7.77 7.93159C7.69444 7.90107 7.61351 7.88608 7.53204 7.88752C7.45056 7.88896 7.37021 7.90679 7.29578 7.93996C7.22135 7.97312 7.15435 8.02094 7.0988 8.08056L4.49363 10.6857L1.88845 8.08056C1.77342 7.97337 1.62128 7.91502 1.46408 7.91779C1.30688 7.92057 1.15689 7.98425 1.04572 8.09542C0.93454 8.2066 0.870857 8.35659 0.868084 8.51379C0.86531 8.67099 0.923663 8.82313 1.03085 8.93816L4.06482 11.9721C4.1786 12.0858 4.33283 12.1496 4.49363 12.1496C4.65443 12.1496 4.80865 12.0858 4.92243 11.9721L7.95641 8.93816V8.93816ZM1.03085 3.36103C0.923663 3.47606 0.86531 3.6282 0.868084 3.7854C0.870858 3.94261 0.93454 4.09259 1.04572 4.20377C1.15689 4.31495 1.30688 4.37863 1.46408 4.3814C1.62128 4.38418 1.77342 4.32582 1.88845 4.21864L4.49363 1.61346L7.0988 4.21864C7.21383 4.32582 7.36597 4.38418 7.52317 4.3814C7.68038 4.37863 7.83036 4.31495 7.94154 4.20377C8.05271 4.09259 8.1164 3.94261 8.11917 3.78541C8.12194 3.6282 8.06359 3.47606 7.95641 3.36103L4.92243 0.327057C4.80866 0.213424 4.65443 0.149597 4.49363 0.149597C4.33283 0.149597 4.1786 0.213424 4.06483 0.327057L1.03085 3.36103V3.36103Z' fill='%232E5599'/%3E%3C/svg%3E%0A");
    }
    .rs-table-cell-header-icon-sort-asc::after {
        content: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.26342 3.33131L1.76689 5.82785L0.661666 4.72262L4.49166 0.892623L5.04505 0.339226L5.59689 0.89106L9.42688 4.72106L8.32165 5.82785L5.82669 3.33131L5.82668 16L4.26342 16L4.26342 3.33131Z' fill='%232E5599'/%3E%3C/svg%3E%0A");
    }
    .rs-table-cell-header-icon-sort-desc::after {
        content: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.82508 13.0079L8.32161 10.5114L9.42684 11.6166L5.59684 15.4466L5.04345 16L4.49162 15.4482L0.661621 11.6182L1.76685 10.5114L4.26182 13.0079V0.339233H5.82508V13.0079Z' fill='%232E5599'/%3E%3C/svg%3E%0A");
    }

    //scrollbar
    .rs-table-scrollbar-handle {
        background: rgba(var(--rgb-primary), 0.8);
    }
}
