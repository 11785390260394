:root {
    --scrollbar-bg: rgba(45, 45, 45, 0.05);
    --scrollbar-color: rgba(var(--rgb-primary), 0.8);
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 4px;
}
