// ==================
// Variables
:root {
    --color-white: #ffffff;
    --color-dark: #131313;
    --color-light: #f5f7fa;
    --color-primary: #2e5599;
    --color-secondary: #cbd4e5;
    --color-error: #d94343;
    --color-warning: #e77e1d;
    --color-success: #34a13f;

    --rgb-primary: 46, 85, 153;
    --rgb-error: 217, 67, 67;
    --rgb-warning: 231, 126, 29;
    --rgb-success: 52, 161, 63;

    --border-radius: 8px;

    --font: 'Poppins', sans-serif;

    --space: 0.5rem;
    --container-padding: calc(var(--space) * 1);
    --container-width: calc(100vw - var(--container-padding) * 2);
    --max-width: 1200px;
    --vh100: calc(var(--vh, 1vh) * 100);

    --nav-width: 72px;

    // Toastify
    --toastify-color-success: var(--color-success) !important;
    --toastify-color-error: var(--color-error) !important;
    --toastify-color-dark: var(--color-dark) !important;
    --toastify-font-family: var(--font) !important;
}

@include up(768) {
    :root {
        --container-padding: calc(var(--space) * 12);
    }
}
