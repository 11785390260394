html {
    height: 100%;
    overflow: auto;
}

body {
    height: 100%;
    color: var(--color-dark);
    background: var(--color-light);
    font-family: var(--font);
    font-size: 14px;
    line-height: 1.6;
    word-break: break-word;
    min-height: 100%;
    margin: auto;

    min-height: 100vh;
}

@include up(992) {
    body {
        font-size: 16px;
    }
}

// Container
// ==============
.container {
    width: 100%;
    max-width: var(--max-width);
    margin-right: auto;
    margin-left: auto;
    padding-left: calc(50% - var(--container-width) / 2);
    padding-right: calc(50% - var(--container-width) / 2);
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    position: relative;
    min-height: 100vh;
}

.section {
    margin: space(4);
    margin-top: 0;
    border-radius: var(--border-radius);
}

.w-100 {
    width: 100%;
}
