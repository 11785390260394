.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-white);
    padding: space(4);
    box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: flex-end;
}
