.filters {
    position: relative;
    z-index: 1000;
}

.filters-outer {
    position: sticky;
    top: -1px;
    padding-bottom: space(3);
    z-index: 10;
    background-color: var(--color-light);
}

.filters-inner {
    background-color: var(--color-white);
    padding: space(3) space(4);
}
