.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.three-quarter-spinner {
    border: 3px solid var(--color-white);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
    width: 1rem;
    height: 1rem;
}

.loader {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        position: relative;
        width: 45px;
        height: 45px;
        margin: 0 auto;
    }

    .bubble-1,
    .bubble-2 {
        position: absolute;
        top: 0;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: var(--color-primary);
    }

    .bubble-2 {
        top: auto;
        bottom: 0;
    }

    /* =Animate the stuff
      ------------------------ */
    .spinner {
        animation: loading 2s linear infinite;
    }
    .bubble-1,
    .bubble-2 {
        animation: bounce 2s ease-in-out infinite;
    }
    .bubble-2 {
        animation-delay: -1s;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}
@keyframes loading {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
