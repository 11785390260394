.product-details {
    min-height: 100%;
    background-color: var(--color-white);
    padding: space(4);
    width: 500px;
    max-width: 75vw;

    & > * {
        margin-bottom: space(4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        border-bottom: 1px solid var(--color-primary);
        padding-bottom: space(2);
    }

    .swiper-slide {
        width: auto;
        height: 250px;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.product-details-loader {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    max-width: 75vw;
    background-color: var(--color-white);
}
